import * as React from 'react';
import * as styles from './faq.module.css';

import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Base/Layout';
import { SEO } from '../components/Base/Seo';
import { Button, Text, Link } from '@chakra-ui/react';

const PriceMatchProgram: FunctionComponent = () => {

    return (
        <>
            <Layout>

                <div className={styles.faq}>
                    <div className={styles.privacypolicyslot}>
                        <div className={styles.titlebox}>
                            <div className={styles.divider} />
                            <div className={styles.title}>
                                <h1 className={styles.frequentlyAskedQuestions}>
                                    Price Match Program
                                </h1>
                            </div>
                            <Text textAlign="center" fontSize="16px">We are committed to offering competitive prices on the high-quality medical products you trust. <br></br> Our Price Match Program ensures you always get the best value on your purchase.</Text>
                        </div>
                        <div className={styles.textcolumn}>
                            <div className={styles.textbody}>
                                <Text
                                    fontWeight={700}
                                    className={styles.whatAreYour}>
                                    How It Works
                                </Text>
                            </div>
                            <div className={styles.textbody}>
                                <Text className={styles.youCanReach}>
                                    If you find a lower price on an identical product from a reputable online supplier, we’ll do our best to meet or beat the price.
                                    You just have to provide Proof of Price, such as an invoice showcasing the terms of sale, or a purchase website displaying the price.
                                    There’s no order minimum for participating in Price Match program. You can request a price match for a single product or larger quantities.

                                </Text>
                            </div>

                            <div className={styles.textbody}>
                                <Text
                                    fontWeight={700}
                                    className={styles.whatAreYour}>
                                    What’s Eligible?
                                </Text>
                            </div>
                            <div className={styles.textbody7}>
                                <ul>
                                    <li><b>Same Product:</b> The product must be identical to the one we sell—same name, manufacturer, and specifications.</li>
                                    <li><b>Online Sellers Serving in the Same Market:</b> We will only match prices from reputable online suppliers operating in the same market as Doctor Medica.</li>
                                    <li><b>Standard, Sale, and Bulk Buy Prices:</b> In addition to standard prices, we’re also matching current discount prices and bulk buy prices*.</li>
                                </ul>

                                <Text pt="8px"><i>*We match other suppliers’ bulk buy prices only when you purchase the same quantity with us.</i></Text>
                            </div>

                            <div className={styles.textbody}>
                                <Text
                                    fontWeight={700}
                                    className={styles.whatAreYour}>
                                    Limitations to Keep in Mind
                                </Text>
                            </div>
                            <div className={styles.textbody7}>
                                <ul>
                                    <li><b>Different currencies.</b> Only USD prices will be considered. </li>
                                    <li><b>Brick-and-mortar suppliers.</b> We match only prices from online stores and reps.</li>
                                    <li><b>Suppliers who don’t operate in the US market.</b> The price match only applies to suppliers servicing the same market as Doctor Medica.</li>
                                    <li><b>Terms of sale.</b> In addition to the product price, our price match includes a review of the full terms of sale, including shipping and delivery.</li>

                                </ul>
                            </div>

                            <div className={styles.textbody}>
                                <Text
                                    
                                    fontWeight={700}
                                    className={styles.whatAreYour}>
                                    Request a Price Match
                                </Text>
                            </div>
                            <div className={styles.textbody7}>
                                <Text className={styles.youCanReach} pb="16px">
                                    Contact our customer service and start enjoying the perks of the Doctor Medica Price Match Program.
                                </Text>
                                <Link href="/contact-us">
                                    <Button variant="solidBlack" bg='#000'
                                        color='#fff'
                                        border='1px solid black'
                                        textTransform='uppercase'
                                        height='40px'
                                        fontSize='14px'
                                        fontWeight='700'
                                        borderRadius='48px'
                                        _hover={{
                                            color: '#000',
                                            bg: '#fff',
                                            border: '1px solid #120B0C'
                                        }}>CONTACT US</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout >
        </>
    );
};

export const Head = () => (
    <SEO
        title='Price Match Program for Guaranteed Savings'
        description="Found a lower price on medical products? We'll match it! Our Price Match Program ensures you always get the best deal, easy and hassle-free."
    />
);

export default PriceMatchProgram;
